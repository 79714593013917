export const formatDateYmd = (date: Date): string => {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
};

export const formatDateDmy = (date: Date): string => {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [day, month, year].join('-');
};

export const formatTime = (date: Date): string => {
    return padToTwo(date.getHours()) + ":" + padToTwo(date.getMinutes());
};

export const padToTwo = (number: number) => `0${number}`.slice(-2);

export const formatCurrencyAmount = (amount: number): string => {
    let formatted = String(amount);
    if (!formatted.includes(".")) return formatted + ".00";

    const decimals = formatted.split(".")[1];
    if (decimals.length >= 2) return formatted.split(".")[0] + "." + decimals.substr(0, 2);
    return formatted.split(".")[0] + "." + decimals + "0";
};

export const formatDateTime = (timestamp: number): string => {
    timestamp *= 1000;
    let date = new Date(timestamp);
    return formatDateDmy(date) + " " + formatTime(date);
};

export const formatMileage = (mileage: number): string => `000000${mileage}`.slice(-6);