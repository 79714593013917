import {Route, RouteProps, useParams} from "react-router";
import {useContext, useEffect} from "react";
import SelectedCarContext from "./SelectedCarContext";
import * as React from "react";


const UpdaterComponent = () => {
	const {setCarId} = useContext(SelectedCarContext);
	const params = useParams<{carId?: string}>();
	const carId: number | undefined = params.carId ? parseInt(params.carId) : undefined;

	useEffect(() => {
		setCarId(carId);
	}, [carId]);
	return null;
};

const WrappedRoute = (props: RouteProps) => {
	return (
		<Route {...props}>
			<UpdaterComponent />
			{props.children}
		</Route>
	);
};
export default WrappedRoute;