import React, {useContext, useRef, useState} from "react";
import {AxiosResponse} from "axios";
import {useTranslation} from 'react-i18next';
import {useAuthenticatedApi} from "../hooks/useAuthenticatedApi";
import {Box, Button, TextField} from "@material-ui/core";
import SelectedCarContext from "./SelectedCarContext";
import PaperWrapper from "./PaperWrapper";
import {useHistory} from "react-router";

export const SendInvite = (): React.ReactElement => {
    const {t} = useTranslation();
    const [createdInvitation, setCreatedInvitation] = useState<boolean>(false);
    const [inviteLink, setInviteLink] = useState<string>("");
    const [linkCopied, setLinkCopied] = useState<boolean>(false);
    const linkInputRef = useRef(null);
    const {carId} = useContext(SelectedCarContext)
    const authenticatedApiClient = useAuthenticatedApi();
    const history = useHistory();

    const handleCreateInvitation = (): void => {
        authenticatedApiClient.post("/createInvitation",  {carId}).then((res: AxiosResponse) => {
            if (res.data.status === "SUCCESS") {
                setCreatedInvitation(true);
                setInviteLink(process.env.REACT_APP_FRONTEND_URL + "/invite/" + res.data.inviteToken);
            }
        });
    };

    const handleClickInviteLink = (): void => {
        // @ts-ignore
        linkInputRef.current.select();
    };

    const handleCopyLink = (e: React.FormEvent<HTMLButtonElement>): void => {
        if (linkInputRef === null) return;

        // @ts-ignore
        linkInputRef.current.select();
        document.execCommand('copy');
        setLinkCopied(true);
    };

    if (!createdInvitation){
        return (
            <PaperWrapper>
                <p>{t("createInvitationText1")}</p>
                <p>{t("createInvitationText2")}</p>
                <Box display="flex" justifyContent="flex-end" py={2}>
                    <Box>
                        <Button color="secondary" variant="contained" onClick={() => history.push(`/cars/${carId}`)}>
                            {t("back")}
                        </Button>
                    </Box>
                    <Box ml={2}>
                        <Button color="primary" variant="contained" onClick={handleCreateInvitation}>
                            {t("createInvitation")}
                        </Button>
                    </Box>
                </Box>
            </PaperWrapper>
        );
    } else {
        return (
            <PaperWrapper>
                <Box p={2}>
                    <p>{t("createInvitationText3")}</p>
                    <Box my={2}>
                        <TextField
                            inputRef={linkInputRef}
                            label={t("name")}
                            id="inviteLink"
                            variant="filled"
                            size="small"
                            value={inviteLink}
                            onChange={handleClickInviteLink}
                        />
                    </Box>
                    {(linkCopied)? t("copiedToClipboard") : null}
                    <Box display="flex" justifyContent="flex-end" py={2}>
                        <Box>
                            <Button color="secondary" variant="contained" onClick={() => history.push(`/cars/${carId}`)}>
                                {t("back")}
                            </Button>
                        </Box>
                        <Box ml={2}>
                            <Button color="primary" variant="contained" onClick={handleCopyLink}>
                                {t("copyLink")}
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </PaperWrapper>
        );
    }
};