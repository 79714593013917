import * as React from "react";
import {useContext, useState} from "react";
import {formatDateYmd} from "../util/formatting";
import {useHistory} from "react-router";
import {useEffect} from "react";
import {ErrorMessages} from "./ErrorMessages";
import {useTranslation} from 'react-i18next';
import {useAuthenticatedApi} from "../hooks/useAuthenticatedApi";
import SelectedCarContext from "./SelectedCarContext";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {Box, Button, Grid, makeStyles, Paper, TextField} from "@material-ui/core";
import PaperWrapper from "./PaperWrapper";


export type EditRefuelFormProps = {
	onFinished?: () => void;
	mode: "new" | "edit";
	refuelId?: number
}

const useStyles = makeStyles({
    textField: {
        width: '100%'
    }
});

export const EditRefuelForm = ({mode, refuelId, onFinished}: EditRefuelFormProps): React.ReactElement => {
    const classes = useStyles();
    const history = useHistory();
    const authenticatedApi = useAuthenticatedApi();
    const {t} = useTranslation();
    const {carId, reloadCar} = useContext(SelectedCarContext);
    const [formVals, setFormVals] = useState<FormVals>({
        date: new Date(),
        moneyAmount: undefined,
        fuelAmount: undefined
    });

    useEffect(() => {
        if (!carId || !refuelId || mode !== "edit") return;

        authenticatedApi.get('/getRefuel', {params: {refuelId: refuelId}}).then((res) => {
            setFormVals({
                date: res.data.refuel.date,
                moneyAmount: res.data.refuel.moneyAmount,
                fuelAmount: res.data.refuel.fuelAmount
            });
        });
    }, []);

    const handleUpdateForm = (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement> | Date | null):void => {
        if (!e || e instanceof Date) setFormVals({...formVals, date: e});
        else setFormVals({...formVals, [e.currentTarget.id]: e.currentTarget.value})
    };

    const [errorMessages, setErrorMessages] = useState<string[]>([]);

    const handleSubmit = (e: React.FormEvent<HTMLButtonElement>): void => {
        e.preventDefault();
        setErrorMessages([]);
        if (!carId) return;

        const data: {[k: string]: any} = {
            date: formVals.date ? formatDateYmd(formVals.date) : formatDateYmd(new Date()),
            moneyAmount: String(formVals.moneyAmount),
            fuelAmount: String(formVals.fuelAmount),
            carId
        };

        let endpoint: string;
        if (mode === "edit") {
            endpoint = "/editRefuel";
            data.refuelId = refuelId;
        } else {
            endpoint = "/addRefuel";
        }

        authenticatedApi.post(endpoint, data).then(res => {
            switch(res.data.status){
                case "ERROR_INVALID_ARGUMENTS":
                    setErrorMessages(res.data.errors);
                    break;
                case "SUCCESS":
                    reloadCar();
                    if (onFinished) onFinished();
                    else history.goBack();
                    break;
            }
        });
    };

    const handleCancel = () => {
		if (onFinished) onFinished();
		else history.goBack();
	};

    return (
    <PaperWrapper>
        <ErrorMessages errors={errorMessages} />
        <form>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            format="dd-MM-yyyy"
                            margin="normal"
                            id="date"
                            label={t("date")}
                            value={formVals.date}
                            className={classes.textField}
                            onChange={handleUpdateForm}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label={t("cost")}
                        id="moneyAmount"
                        variant="filled"
                        size="small"
                        type="number"
                        className={classes.textField}
                        value={formVals.moneyAmount}
                        onChange={handleUpdateForm}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label={t("amountOfFuel")}
                        id="fuelAmount"
                        variant="filled"
                        size="small"
                        type="number"
                        className={classes.textField}
                        value={formVals.fuelAmount}
                        onChange={handleUpdateForm}
                    />
                </Grid>
            </Grid>
            <Box my={2}>
                <Box display="flex" justifyContent="flex-end">
                  <Box>
                    <Button color="secondary"variant="contained" onClick={handleCancel}>
                      {t("cancel")}
                    </Button>
                  </Box>
                  <Box pl={2}>
                    <Button color="primary" variant="contained" onClick={handleSubmit}>
                      {t("save")}
                    </Button>
                  </Box>
                </Box>
            </Box>
        </form>
    </PaperWrapper>
    );
};

interface FormVals {
    date: Date | null,
    fuelAmount: number | undefined,
    moneyAmount: number | undefined
}