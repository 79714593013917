import {ReactElement} from "react";
// import scss from "./UserBadge.module.scss";
import React from "react";

export const UserBadge = (props: {user: IUser, status?: boolean}): ReactElement => {
    // if (props.status !== undefined && props.status === false)classNames.push(scss.negative);

    return (
        <div>
            {props.user.name.substr(0, 1)}
        </div>
    )
}