import React, {useEffect, useState} from "react";
import {useHistory, useParams} from "react-router";
import {LoadingIndicator} from "./LoadingIndicator";
import { useTranslation } from 'react-i18next';
import {formatCurrencyAmount} from "../util/formatting";
import {useAuthenticatedApi} from "../hooks/useAuthenticatedApi";
import {Box, Button, Table, TableBody, TableCell, TableRow, Typography} from "@material-ui/core";
import {Chart} from "react-google-charts";
import PaperWrapper from "./PaperWrapper";

export const Stats = (): React.ReactElement => {
    const history = useHistory();
    const params = useParams<{carId: string | undefined}>();
    const carId: number = (params.carId) ? parseInt(params.carId) : 0;
    const [statsData, setStatsData] = useState<ICarStats | null>();
    const [chartData, setChartData] = useState<DataRowItem[]>();
    const {t} = useTranslation();
    const authenticatedApiClient = useAuthenticatedApi();

    useEffect(() => {
        authenticatedApiClient.get('/getStats', {params: {carId}}).then(res => {
            setStatsData(res.data.stats);
        });
    }, []);


    useEffect((): void => {
        if (!statsData) return;

        const data: DataRowItem[] = [[t('date'), t('distance') as string]];
        statsData.monthlyDataArray.forEach(row => data.push([new Date(row[0]), row[1]]));
        setChartData(data);
    }, [statsData]);

    if (!statsData) {
        return <LoadingIndicator/>;
    }

    return (
			<PaperWrapper>
				<Typography variant="h3">{t("titles.statistics")}</Typography>
				<Table>
					<TableBody>
						<TableRow>
							<TableCell>{t("totalDistance")}</TableCell>
							<TableCell align="right">{statsData.totalDistance} km</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>{t("totalMoneySpent")}</TableCell>
							<TableCell align="right">€{formatCurrencyAmount(statsData.totalPaid)}</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>{t("numberOfRides")}</TableCell>
							<TableCell align="right">{statsData.numberOfRides}</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>{t("totalFuelConsumed")}</TableCell>
							<TableCell align="right">{formatCurrencyAmount(statsData.totalFuelConsumed)} liter</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>{t("fuelConsumption")}</TableCell>
							<TableCell align="right">{formatCurrencyAmount(statsData.fuelConsumption)} km/l</TableCell>
						</TableRow>
					</TableBody>
				</Table>
				<Chart
					chartType="AreaChart"
					data={chartData}
					width="100%"
					height="400px"
					options={{
						title: t('distancePerDay'),
						chartArea: {width: "82%"},
						titleTextStyle: {
							color: '#efefef'
						},
						hAxis: {textStyle: {color: '#efefef'}},
						vAxis: {minValue: 0, textStyle: {color: '#efefef'}},
						backgroundColor: '#424242',
						legend: {position: 'none'}
					}}
				/>
				<Box display="flex" justifyContent="flex-end" py={2}>
					<Box>
						<Button color="secondary" variant="contained" onClick={() => history.goBack()}>
							{t("back")}
						</Button>
					</Box>
				</Box>
			</PaperWrapper>
    )
};

interface ICarStats {
    fuelConsumption: number;
    totalFuelConsumed: number;
    numberOfRides: number;
    totalPaid: number;
    totalDistance: number;
    monthlyDataArray: [string, number][]
}

type DataRowItem = [string | Date, number | string];