import React, {useContext, useRef, useState} from "react";
import ApiClient from "../util/apiclient";
import {AxiosResponse} from "axios";
import {ErrorMessages} from "./ErrorMessages";
import {MileageCounter} from "./MileageCounter";
import {useTranslation} from 'react-i18next';
import CarsContext from "./CarsContext";
import {Box, Button, TextField} from "@material-ui/core";
import PaperWrapper from "./PaperWrapper";

export type AddCarProps = {
	onFinished: () => void;
}

const AddCar = ({onFinished}: AddCarProps): React.ReactElement => {
	const {t} = useTranslation();
	const [errorMessages, setErrorMessages] = useState<string[]>([]);
	const [formVals, setFormVals] = useState<FormVals>({
		name: "",
		mileage: 0
	});
	const fileRef = useRef<HTMLInputElement>(null);
	const [fileName, setFileName] = useState<string>("");

	const {reloadCars} = useContext(CarsContext);

	const handleUpdateForm = (e: React.FormEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
		setFormVals({...formVals, [e.currentTarget.id]: e.currentTarget.value});
	};

	const handleUpdateMileage = (mileage: number) => {
		setFormVals({...formVals, mileage});
	};

	const handleSubmit = (): void => {
		setErrorMessages([]);

		const image = (fileRef.current && fileRef.current.files && fileRef.current.files.length) ? fileRef.current.files[0] : undefined;

		ApiClient.post('/addCar', {name: formVals.name, mileage: String(formVals.mileage), image}, {
			headers: {
				'content-type': 'multipart/form-data'
			}
		}).then((res: AxiosResponse) => {
			if (res.data.status === "SUCCESS") {
				reloadCars();
				onFinished();
			} else setErrorMessages(res.data.errors);
		});
	};

	const handleCancel = () => {
		onFinished();
	};

	const handleFileChanged = () => {
		if (!fileRef.current || !fileRef.current.files || !fileRef.current.files.length) {
			setFileName("");
		} else {
			setFileName(fileRef.current.files[0].name);
		}
	};

	return (
		<PaperWrapper>
			<ErrorMessages errors={errorMessages}/>
			<form>
				<Box my={2}>
					<TextField
						label={t("name")}
						id="name"
						variant="filled"
						size="small"
						value={formVals.name}
						onChange={handleUpdateForm}
					/>
				</Box>
				<Box my={2}>
					<label>{t("mileage")}</label>
					<MileageCounter mileage={0} editable={true} editHandler={handleUpdateMileage}/>
				</Box>
				<Box my={2}>
					<Button variant="contained" component="label">
						{t("uploadImage")}
						<input
							ref={fileRef}
							accept="image/*"
							hidden
							type="file"
							onChange={handleFileChanged}
						/>
					</Button>
					<Box my={2}>{fileName}</Box>
				</Box>
				<Box my={2}>
					<Box display="flex" justifyContent="flex-end">
						<Box>
							<Button color="secondary" variant="contained" onClick={handleCancel}>
								{t("cancel")}
							</Button>
						</Box>
						<Box pl={2}>
							<Button color="primary" variant="contained" onClick={handleSubmit}>
								{t("save")}
							</Button>
						</Box>
					</Box>
				</Box>
			</form>
		</PaperWrapper>
	);
};

interface FormVals {
	name: string;
	mileage: number;
}

export default AddCar;