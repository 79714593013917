import React, {createContext, ReactElement, useContext, useEffect, useState} from "react";
import {useAuthenticatedApi} from "../hooks/useAuthenticatedApi";
import UserContext from "./UserContext";

type CarsContextValue = {
	loading: boolean
	cars: ICar[];
	reloadCars: () => void
}

const defaultValue: CarsContextValue = {
	loading: false,
	cars: [],
	reloadCars: () => {}
};


const CarsContext = createContext<CarsContextValue>(defaultValue);
export default CarsContext;


type CarsContextProviderProps = {
	children?: ReactElement;
}

export const CarsContextProvider = ({children}: CarsContextProviderProps) => {
	const [loading, setLoading] = useState<boolean>(false);
	const [cars, setCars] = useState<ICar[]>([]);
	const authenticatedApiClient = useAuthenticatedApi();
	const {setInvites, user} = useContext(UserContext);

	const reloadCars = async () => {
		setLoading(true);
		const res = await authenticatedApiClient.get("/getCars");
		if (res.data.status === "SUCCESS") {
			setCars(res.data.cars);
			if (res.data.invites) setInvites(res.data.invites);
			else setInvites([]);
		}
		setLoading(false);
	};

	useEffect(() => {
		if (user){
			reloadCars();
		} else {
			setCars([]);
		}
	}, [user]);

	const initialValue: CarsContextValue = {
		loading,
		cars,
		reloadCars
	};

	return (
		<CarsContext.Provider value={initialValue}>
			{children}
		</CarsContext.Provider>
	)
};

