import React, {useState} from "react";
import {useHistory} from "react-router";
import {ErrorMessages} from "./ErrorMessages";
import {useTranslation} from 'react-i18next';
import {useAuthenticatedApi} from "../hooks/useAuthenticatedApi";

export const Register = (): React.ReactElement => {
    const {t} = useTranslation();
    const [formVals, setFormVals] = useState<FormVals>({
        name: "",
        email: "",
        password: "",
        passwordConfirm: ""
    });
    const authenticatedApiClient = useAuthenticatedApi();

    const [errorMessages, setErrorMessages] = useState<string[]>([]);

    const handleChangeFormVals = (e: React.FormEvent<HTMLInputElement>): void => {
        setFormVals({...formVals, [e.currentTarget.id]: e.currentTarget.value})
    };

    const inviteToken = localStorage.getItem("inviteToken");

    const history = useHistory();

    const handleSubmit = (e: React.FormEvent<HTMLInputElement>): void => {
        e.preventDefault();
        setErrorMessages([]);
        if (formVals.password !== formVals.passwordConfirm) {
            setErrorMessages(["errors.PASSWORDS_DONT_MATCH"]);
            return;
        }

        const data: {[k: string]: string} = {
            name: formVals.name,
            email: formVals.email,
            password: formVals.password,
        };

        if (inviteToken) data.inviteToken = inviteToken;

        authenticatedApiClient.post("/register",  data).then(res => {
            if (res.data.status === "SUCCESS") {
                localStorage.setItem("token", res.data.token);

                // TODO: show error message when failed to link invitation
                if (res.data.hasOwnProperty("inviteLinked")) localStorage.removeItem("inviteToken");
                history.push("/cars");
            } else {
                setErrorMessages(res.data.errors);
            }
        });
    };

    if (process.env.REACT_APP_REGISTRATION_ENABLED !== "true") return(
        <div>
            <p>
                {t("registrationNotAvailable")}
            </p>
        </div>
    );

    return (
        <div>
            <p>{t("registrationIntro")}</p>
            <ErrorMessages errors={errorMessages}/>
            <form>
                <div>
                    <label htmlFor="name">{t("name")}</label>
                    <input type="name" id="name" value={formVals.name} onChange={handleChangeFormVals}/>
                </div>
                <div>
                    <label htmlFor="email">{t("email")}</label>
                    <input type="email" id="email" value={formVals.email} onChange={handleChangeFormVals}/>
                </div>
                <div>
                    <label htmlFor="password">{t("password")}</label>
                    <input type="password" id="password" value={formVals.password} onChange={handleChangeFormVals}/>
                </div>
                <div>
                    <label htmlFor="passwordConfirm">{t("confirmPassword")}</label>
                    <input type="password" id="passwordConfirm" value={formVals.passwordConfirm} onChange={handleChangeFormVals}/>
                </div>
                <input className="button" type="button" value={t("register") as string} onClick={handleSubmit}/>
            </form>
        </div>
    );
};

interface FormVals {
    name: string;
    email: string;
    password: string;
    passwordConfirm: string;
}