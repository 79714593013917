import PaperWrapper from "./PaperWrapper";
import {ReactElement, useContext, useRef} from "react";
import UserContext from "./UserContext";
import {useTranslation} from "react-i18next";
import UserAvatar from "./UserAvatar";
import * as React from "react";
import {Box, Button, Grid, useTheme} from "@material-ui/core";
import {useAuthenticatedApi} from "../hooks/useAuthenticatedApi";

const Account = (): ReactElement | null => {
	const {user, checkLoggedIn} = useContext(UserContext);
	const {t} = useTranslation();
	const fileRef = useRef(null);
	const authenticatedApi = useAuthenticatedApi();
	if (!user) return null;

	const handleUploadImage = () => {
		if (!fileRef.current) return;
		// @ts-ignore
		if (fileRef.current.files[0]) {
			// @ts-ignore
			authenticatedApi.post("/uploadAvatar", {image: fileRef.current.files[0]}, {
				headers: {
					'content-type': 'multipart/form-data'
				}
			}).then(res => {
					checkLoggedIn();
				}
			)
		}
	};

	const handleRemoveImage = () => {
		authenticatedApi.post("/removeAvatar").then(checkLoggedIn)
	};

	return (
		<PaperWrapper>
			<Grid container>
				<Grid item xs={9}>
					<Button variant="contained" component="label">
						{t("updateAvatar")}
						<input
							ref={fileRef}
							accept="image/*"
							hidden
							id="raised-button-file"
							type="file"
							onChange={handleUploadImage}
						/>
					</Button>
				</Grid>
				<Grid item xs={3}>
					<UserAvatar user={user}/>
				</Grid>
			</Grid>
			{user.avatar ? (
				<Box py={2}>
					<Button variant="contained" onClick={handleRemoveImage}>
						{t("removeAvatar")}
					</Button>
				</Box>
			) : null}
		</PaperWrapper>
	)
};

export default Account;