import React from "react";
// import scss from "./ErrorMessages.module.scss";
import {useTranslation} from 'react-i18next';

export const ErrorMessages = (props: {errors: string[]}): React.ReactElement | null => {
    const {t} = useTranslation();
    if (props.errors.length === 0) return null;

    const messages = props.errors.map((error, index) => <li key={"error-" + index}>{error}</li>);
    return (
        <div>
            <strong>{t('errorsOccurred', {count: messages.length})}</strong>
            <ul>{messages}</ul>
        </div>
    );
};