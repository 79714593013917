import React from "react";
import {EditRideForm} from "./EditRideForm";
import {useParams} from "react-router";

export const EditRide = (): React.ReactElement | null => {
    const { rideId } = useParams<{rideId?: string}>();

    let parsedRideId: number = -1;
    if (rideId !== undefined) parsedRideId = parseInt(rideId);

    return(
        <EditRideForm mode="edit" rideId={parsedRideId} />
    )
};
