import React, {ReactElement, useContext, useEffect, useState} from "react";
import {useParams, useHistory} from "react-router"
import {LoadingIndicator} from "./LoadingIndicator";
import {formatCurrencyAmount, formatDateDmy} from "../util/formatting";
import {Link} from "react-router-dom";
import {useTranslation} from 'react-i18next';
import {useAuthenticatedApi} from "../hooks/useAuthenticatedApi";
import SelectedCarContext from "./SelectedCarContext";

export const ViewRefuel = (): React.ReactElement => {
    const {t} = useTranslation();
    const params = useParams<{carId: string | undefined; refuelId: string | undefined}>();
    const {car, reloadCar, users} = useContext(SelectedCarContext);
    const refuelId: number | null = (params.refuelId) ? parseInt(params.refuelId) : null;
    const history = useHistory();
    const [refuelDetails, setRefuelDetails] = useState<IRefuel | null>(null);
    const [editable, setEditable] = useState<boolean>(false);
    const authenticatedApiClient = useAuthenticatedApi();

    useEffect(() => {
        if (!car || !refuelId) return;

        authenticatedApiClient.get('/getRefuel', {params: {refuelId}}).then((res) => {
            setRefuelDetails(res.data.refuel);
            setEditable(res.data.editable);
        });
    }, [car, refuelId]);

    if (!refuelDetails || !car || !users) return <LoadingIndicator/>;
    const refuelUser = users.find(user => user.id === refuelDetails.userId) as IUser;

    const handleDeleteRefuel = (): void => {
        authenticatedApiClient.post('/removeRefuel', {refuelId}).then(res => {
            reloadCar();
            history.push(`/cars/${car.id}`);
        });
    };

    const actionButtons: ReactElement | null = editable ? (
        <div className="actionButtonsWrapper">
            <Link to={`/editRefuel/${car.id}/${refuelId}`}></Link>
            <span onClick={handleDeleteRefuel}></span>
        </div>
    ) : null;

    const refuelDate: string = formatDateDmy(new Date(refuelDetails.date));

    return (
        <div>
            {actionButtons}
            <div className="wrapper">
                <div className="dataRow"><span>{t("date")}</span><span>{refuelDate}</span></div>
                <div className="dataRow"><span>{t("moneySpent")}</span><span>€{formatCurrencyAmount(refuelDetails.moneyAmount)}</span></div>
                <div className="dataRow"><span>{t("amountOfFuel")}</span><span>{formatCurrencyAmount(refuelDetails.fuelAmount)} liter</span></div>
                <div className="dataRow"><span>{t("pricePerLiter")}</span><span>€{formatCurrencyAmount(Math.round((refuelDetails.moneyAmount / refuelDetails.fuelAmount) * 100) / 100)}</span></div>
                <div className="dataRow"><span>{t("paidBy")}</span>{refuelUser.name}</div>
            </div>
        </div>
    );
};