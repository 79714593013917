import {Avatar, AvatarProps, makeStyles} from "@material-ui/core";
import React from "react";
import {blueGrey, deepOrange, deepPurple} from "@material-ui/core/colors";
import clsx from "clsx";

type UserAvaterProps = AvatarProps & {
	user: IUser;
}


const avatarColorStyles = makeStyles((theme) => ({
	orange: {
		color: theme.palette.getContrastText(deepOrange[500]),
		backgroundColor: deepOrange[500],
	},
	purple: {
		color: theme.palette.getContrastText(deepPurple[500]),
		backgroundColor: deepPurple[500],
	},
	blue: {
		color: theme.palette.getContrastText(blueGrey[500]),
		backgroundColor: blueGrey[500]
	}
}));

const UserAvatar = ({className, user, src, ...props}: UserAvaterProps) => {
	const avatarColors = avatarColorStyles();
	const avatarColorsAvailable = ["orange", "purple", "blue"];
	const bgColorIndex = ( user.name.charCodeAt(0) + user.name.length) % avatarColorsAvailable.length;
	let imageSrc = src ? src : user.avatar;
	if (imageSrc) imageSrc = process.env.REACT_APP_API_URL + "/" + imageSrc;

	// @ts-ignore
	return <Avatar src={imageSrc} className={clsx(avatarColors[avatarColorsAvailable[bgColorIndex]], className)} alt={user.name} {...props}>{user.name.substr(0, 1)}</Avatar>;
}

export default UserAvatar;