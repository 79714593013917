import React, {useContext, useEffect, useState} from "react";
import {EventList} from "./EventList";
import SelectedCarContext from "./SelectedCarContext";
import LayoutContext from "./Layout/LayoutContext";
import {Box, IconButton, makeStyles, Theme} from "@material-ui/core";
import ShareIcon from '@material-ui/icons/Share';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import {useHistory} from "react-router";
import {AddRide} from "./AddRide";
import {AddRefuel} from "./AddRefuel";
import {GetUnsettled} from "./getUnsettled";

type OverlayTypes = "ADD_RIDE" | "ADD_REFUEL" | "GET_UNSETTLED";

const useStyles = makeStyles({
  carImage: {
    backgroundSize: "cover",
    backgroundPosition: "center center",
    height: '220px'
  }
});

export const CarDetails = (): React.ReactElement | null => {
    const classes = useStyles();
    const {events} = useContext(SelectedCarContext);
    const {setToolbar} = useContext(LayoutContext);
    const history = useHistory();
    const {carId, car} = useContext(SelectedCarContext);
    const [showOverlay, setShowOverlay] = useState<OverlayTypes | undefined>();

    const handleOverlayFinished = () => {
        setShowOverlay(undefined);
    };

    const toolbar = (
        <>
            <IconButton onClick={() => setShowOverlay("ADD_RIDE")}>
                <i className="fas fa-road"></i>
            </IconButton>
            <IconButton onClick={() => setShowOverlay("ADD_REFUEL")}>
                <i className="fas fa-gas-pump"></i>
            </IconButton>
            <IconButton onClick={() => setShowOverlay("GET_UNSETTLED")}>
                <i className="fas fa-random"></i>
            </IconButton>
            <IconButton onClick={() => history.push(`/sendInvite/${carId}`)}>
                <ShareIcon />
            </IconButton>
            <IconButton onClick={() => history.push(`/stats/${carId}`)}>
                <EqualizerIcon />
            </IconButton>
        </>
    );

    useEffect(() => {
        setToolbar(toolbar);
        return () => {
            setToolbar(null);
        }
    }, [carId]);

    if (!car) return null;

    if (showOverlay){
        switch(showOverlay){
            case "ADD_RIDE":
                return <AddRide onFinished={handleOverlayFinished} />;
            case "ADD_REFUEL":
                return <AddRefuel onFinished={handleOverlayFinished}/>;

            case "GET_UNSETTLED":
                return <GetUnsettled onFinished={handleOverlayFinished}/>;
        }
    }

    return (
      <>
        {car.image ? (
            <Box className={classes.carImage} style={{backgroundImage: `url("${process.env.REACT_APP_API_URL}/${car.image}")`}} />
        ) : null}
        <EventList events={events}/>
      </>
    );
};

CarDetails.hasWrapper = false;