import {useParams} from "react-router";
import * as React from "react";
import {EditRefuelForm} from "./EditRefuelForm";

export const EditRefuel = (): React.ReactElement | null => {
    const params = useParams<{carId: string | undefined; refuelId: string | undefined}>();
    const carId: number | null = (params.carId) ? parseInt(params.carId) : null;
    const refuelId: number | null = (params.refuelId) ? parseInt(params.refuelId) : null;

    if (!carId || !refuelId) return null;

    return (
        <EditRefuelForm mode="edit" refuelId={refuelId} />
    )
};