import {ReactElement, useContext, useRef, useState} from "react";
import ApiClient from "../util/apiclient";
import * as React from "react";
import {useHistory} from "react-router";
import {AxiosResponse} from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import {ErrorMessages} from "./ErrorMessages";
import {useTranslation} from 'react-i18next';
import UserContext from "./UserContext";
import {Box, Button, Grid, makeStyles, TextField} from "@material-ui/core";

const useStyles = makeStyles({
	textField: {
		width: '100%'
	}
});

export default function LoginForm(): ReactElement {
	const classes = useStyles();
	const history = useHistory();
	const {t} = useTranslation();
	const [username, setUserName] = useState<string>("");
	const [password, setPassword] = useState<string>("");
	const [errorMessages, setErrorMessages] = useState<string[]>([]);
	const inviteToken = localStorage.getItem("inviteToken");
	const [loginTriggered, setLoginTriggered] = useState<boolean>(false);
	const {checkLoggedIn} = useContext(UserContext);

	let recaptchaEnabled = true;
	if (process.env.REACT_APP_RECAPTCHA_ENABLED !== undefined && process.env.REACT_APP_RECAPTCHA_ENABLED === "disabled") recaptchaEnabled = false;

	const handleUpdateUsername = (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
			setUserName(e.currentTarget.value);
	};

	const handleUpdatePassword = (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
			setPassword(e.currentTarget.value);
	};

	const captchaChangeHandler = (): void => {
			if (loginTriggered) doLogin();
	};

	const doLogin = (): void => {
			const data: {[k: string]: string} = {
					email: username,
					password,
			};

			if (recaptchaEnabled) {
					// @ts-ignore
					const captchaToken = recaptchaRef.current.getValue();
					if (captchaToken === null || captchaToken === "") return;
					data.recaptchaToken = captchaToken
			}
			if (inviteToken) data.inviteToken = inviteToken;

			ApiClient.post("/login", data).then((res: AxiosResponse) => {
					switch(res.data.status){
							case "ERROR_INVALID_ARGUMENTS":
									setErrorMessages(res.data.errors);

									// @ts-ignore
									recaptchaRef.current.reset();
									break;
							case "SUCCESS":
									// TODO: show error message when failed to link invitation
									localStorage.setItem("token", res.data.token);
									checkLoggedIn();
									if (res.data.hasOwnProperty("inviteLinked")) localStorage.removeItem("inviteToken");
									history.push('/cars');
									break;
					}
			}).catch(err => console.log(err));
			setLoginTriggered(false);
	};

	const handleLogin = (e: React.FormEvent<HTMLButtonElement>): void => {
			e.preventDefault();
			setLoginTriggered(true);
			setErrorMessages([]);

			if (!recaptchaEnabled) doLogin();
			else {
					// @ts-ignore
					recaptchaRef.current.execute();
			}
	};

	const recaptchaRef = useRef(null);

	return (
		<>
			<ErrorMessages errors={errorMessages}/>
			<form>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={6}>
						<TextField
							className={classes.textField}
							label={t("email")}
							id="name"
							variant="filled"
							size="small"
							value={username}
							onChange={handleUpdateUsername}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							className={classes.textField}
							label={t("password")}
							id="name"
							type="password"
							variant="filled"
							size="small"
							value={password}
							onChange={handleUpdatePassword}
						/>
					</Grid>
				</Grid>
				{(recaptchaEnabled) ? (
					<ReCAPTCHA
						ref={recaptchaRef}
						size="invisible"
						sitekey="6LeIEtAUAAAAANLpDwMVyemjeSd4j6bzsc5mbokG"
						onChange={captchaChangeHandler}
					/>
				) : null}
				<Box my={2} display="flex" justifyContent="flex-end">
					<Button type="submit" color="primary" variant="contained" onClick={handleLogin}>
						{t("login")}
					</Button>
				</Box>
			</form>
		</>
	)
}