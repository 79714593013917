import {useState} from "react";
import React from "react";
import {useTranslation} from 'react-i18next';
import {useAuthenticatedApi} from "../hooks/useAuthenticatedApi";
import PaperWrapper from "./PaperWrapper";
import {Box, Button, TextField} from "@material-ui/core";

export const RequestPasswordReset = (): React.ReactElement => {
    const {t} = useTranslation();
    const [email, setEmail] = useState<string>("");
    const [requested, setRequested] = useState<boolean>(false);
    const authenticatedApiClient = useAuthenticatedApi();

    const handleChangeEmail = (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        setEmail(e.currentTarget.value);
    };

    const submit = (e: React.FormEvent<HTMLButtonElement>): void => {
        e.preventDefault();

        authenticatedApiClient.post("/requestPasswordReset", {email}).then(res => {
            setRequested(true);
        });
    };

    if (!requested){
        return(
            <PaperWrapper>
                <form>
                    <p>{t("passwordResetDescription")}</p>
                    <Box my={2}>
                        <TextField
                            label={t("email")}
                            id="email"
                            variant="filled"
                            size="small"
                            value={email}
                            onChange={handleChangeEmail}
                        />
                    </Box>
                    <Box py={2}>
                        <Button color="primary" variant="contained" type="submit" onClick={submit}>
                            {t("send")}
                        </Button>
                    </Box>
                </form>
            </PaperWrapper>

        );
    } else {
        return (
            <PaperWrapper>{t("passwordResetRequested")}</PaperWrapper>
        )

    }
}