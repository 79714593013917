import 'date-fns';
import React, {useContext, useEffect, useState} from "react";
import {useHistory} from "react-router";
import {formatDateYmd} from "../util/formatting";
import {ErrorMessages} from "./ErrorMessages";
import {MileageCounter} from "./MileageCounter";
import {useTranslation} from 'react-i18next';
import {useAuthenticatedApi} from "../hooks/useAuthenticatedApi";
import SelectedCarContext from "./SelectedCarContext";
import {
	Box, Button,
	Grid,
	Input,
	InputLabel,
	makeStyles,
	MenuItem,
	Select,
	TextField
} from "@material-ui/core";
import {
    KeyboardDatePicker, MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import UserContext from "./UserContext";
import PaperWrapper from "./PaperWrapper";
const useStyles = makeStyles({
	formControl: {
		minWidth: 235,
		maxWidth: 300,
	},
	textField: {
		width: '100%'
	}
});

export type EditRideFormProps = {
    onFinished?: () => void;
    mode: "add" | "edit";
    rideId: number
}

export const EditRideForm = ({mode, rideId, onFinished}: EditRideFormProps): React.ReactElement | null => {
    const classes = useStyles();
    const history = useHistory();
    const {car, users, reloadCar} = useContext(SelectedCarContext);
    const authenticatedApiClient = useAuthenticatedApi();
    const {t} = useTranslation();
    const [originalEndMileage, setOriginalEndMileage] = useState<number>(0);
    const [formVals, setFormVals] = useState<FormVals>({
        date: new Date(),
        startMileage: 0,
        endMileage: 0,
        name: "",
        drivers: []
    });

    useEffect((): void => {
        if (!car) return;
        setFormVals({
            ...formVals,
            startMileage: car.mileage,
            endMileage: car.mileage
        })
        setOriginalEndMileage(car.mileage);
    }, [car]);

    const [rideDistance, setRideDistance] = useState<number>(0);
    const [errorMessages, setErrorMessages] = useState<string[]>([]);

    // Updates all form values except driver multiselect
    const handleUpdateForm = (e: React.FormEvent<HTMLTextAreaElement | HTMLInputElement | HTMLTextAreaElement> | Date | null): void => {
        if (!e || e instanceof Date) {
            setFormVals({...formVals, date: e});
        } else {
            setFormVals({...formVals, [e.currentTarget.id]: e.currentTarget.value});
        }
    };

    const handleUpdateNewMileage = (mileage: number): void => {
        setFormVals({...formVals, endMileage: mileage});
        setRideDistance(mileage - formVals.startMileage);
    };

    const {user} = useContext(UserContext);

    const handleSubmitRide = (e: React.FormEvent<HTMLButtonElement>): void => {
        e.preventDefault();

        if (!car) return;

        const data: {[k: string]: string} = {
            carId: String(car.id),
            date: formVals.date ? formatDateYmd(formVals.date) : formatDateYmd(new Date()),
            name: formVals.name,
            start_mileage: String(car.mileage),
            end_mileage: String(formVals.endMileage),
            drivers: formVals.drivers.join(",")
        };

        let apiEndpoint: string;
        if (mode === "edit") {
            data.rideId = String(rideId);
            apiEndpoint = "/editRide";
        } else {
            apiEndpoint = "/addRide"
        }

        authenticatedApiClient.post(apiEndpoint, data).then(res => {
            switch(res.data.status){
                case "SUCCESS":
                    reloadCar();
                    if (onFinished) onFinished();
                    else history.goBack();
                    break;
                default:
                    setErrorMessages(res.data.errors);
            }
        });
    };

    useEffect(() => {
        if (!car || !user) return;

        if (mode === "edit") {
            authenticatedApiClient.get('/getRideDetails', {params: {rideId}}).then(res => {
                const rideResult: IRide = res.data.ride;
                const driverIds = rideResult.drivers.map(driver => driver.userId);
                setFormVals({
                    name: rideResult.name,
                    date: new Date(rideResult.date),
                    startMileage: rideResult.start_mileage,
                    endMileage: rideResult.end_mileage,
                    drivers: driverIds
                });
                setOriginalEndMileage(rideResult.end_mileage);
            });
        } else {
            setFormVals(oldVals => {
                return {...oldVals, drivers: [user.id]}
            });
        }
    }, [car, rideId, user]);

    if (!car || !users) return null;

    const handleChangeDrivers = (event: React.ChangeEvent<{ value: unknown }>) => {
        setFormVals({...formVals, drivers: event.target.value as number[]});
    };

    const handleCancel = () => {
        if (onFinished) onFinished();
        else history.goBack();
    };

    return (
        <PaperWrapper>
					<ErrorMessages errors={errorMessages}/>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<label>{t("from")}</label>
							<MileageCounter mileage={formVals.startMileage} />
						</Grid>
						<Grid item xs={12}>
							<label>{t("to")}</label>
							<MileageCounter mileage={originalEndMileage} editable={true} editHandler={handleUpdateNewMileage} />
						</Grid>
						<Grid item xs={12}>{t("distance")}: {(rideDistance > -1) ? rideDistance : "-"} km</Grid>
						<Grid item xs={12}>
							<MuiPickersUtilsProvider utils={DateFnsUtils}>
								<KeyboardDatePicker
										disableToolbar
										variant="inline"
										format="dd-MM-yyyy"
										margin="normal"
										id="date"
										label={t("date")}
										value={formVals.date}
										className={classes.textField}
										onChange={handleUpdateForm}
								/>
							</MuiPickersUtilsProvider>
						</Grid>
						<Grid item xs={12}>
							<TextField
								label={t("name")}
								id="name"
								variant="filled"
								size="small"
								value={formVals.name}
								className={classes.textField}
								onChange={handleUpdateForm}
							/>
						</Grid>
						<Grid item xs={12}>
							<InputLabel id="driversLabel">{t("participants")}</InputLabel>
							<Select
								labelId="driversLabel"
								id="drivers"
								multiple
								className={classes.textField}
								value={formVals.drivers}
								variant="filled"
								onChange={handleChangeDrivers}
								input={<Input />}
							>
								{users.map((user: IUser) =>
										<MenuItem key={user.id} value={user.id}>
												{user.name}
										</MenuItem>
								)}
							</Select>
						</Grid>
					</Grid>
					<Box my={2} display="flex" justifyContent="flex-end">
							<Box>
									<Button color="secondary"variant="contained" onClick={handleCancel}>
											{t("cancel")}
									</Button>
							</Box>
							<Box pl={2}>
									<Button color="primary" variant="contained" onClick={handleSubmitRide}>
											{t("save")}
									</Button>
							</Box>
					</Box>
				</PaperWrapper>
    )
};

interface FormVals {
    date: Date | null;
    startMileage: number;
    endMileage: number;
    name: string;
    drivers: number[]
}