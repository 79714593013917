import DriveEtaIcon from '@material-ui/icons/DriveEta';
import React, {ReactNode, useContext, useState} from 'react'
import {useHistory} from 'react-router-dom';

import {Helmet} from "react-helmet";
import {
	AppBar,
	Box, Container,
	Divider,
	Drawer,
	IconButton,
	List,
	ListItemIcon, ListItemText,
	Toolbar,
	Typography, useMediaQuery,
	ThemeProvider,
	CssBaseline
} from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";

import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import {makeStyles} from '@material-ui/core/styles';

import clsx from 'clsx';
import LayoutContext from "./LayoutContext";
import UserContext from "../UserContext";
import CarsContext from "../CarsContext";
import {useTranslation} from "react-i18next";
import theme from "../../theme"
import SelectedCarContext from "../SelectedCarContext";
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import HomeIcon from '@material-ui/icons/Home';
import UserAvatar from "../UserAvatar";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
	root: {
		'& .MuiListItemIcon-root': {
			minWidth: 0
		}
	},
	appBar: {
		transition: theme.transitions.create(['margin', 'width'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
	},
	menuButton: {
		marginRight: theme.spacing(2),
		[theme.breakpoints.up('sm')]: {
			display: 'none',
		},
	},
	hide: {
		display: 'none',
	},
	drawer: {
		[theme.breakpoints.up('sm')]: {
			width: drawerWidth,
			flexShrink: 0,
		},
	},
	drawerPaper: {
		width: drawerWidth,
	},
	drawerHeader: {
		display: 'flex',
		alignItems: 'center',
		padding: theme.spacing(0, 1),
		// necessary for content to be below app bar
		...theme.mixins.toolbar,
		justifyContent: 'flex-end',
	},
	content: {
		padding: 0,
		flexGrow: 1
	},
	toolbar: theme.mixins.toolbar,
	spacer: {
		[theme.breakpoints.up('sm')]: {
			minWidth: drawerWidth
		}
	},
	expandableMenuItem: {
		flexGrow: 1
	},
	nested: {
		paddingLeft: theme.spacing(4),
	},
	grow: {
		flexGrow: 1
	}
}));

type LayoutProps = {
	children?: ReactNode;
}
const Layout = ({children}: LayoutProps) => {
	const widthUpSm = useMediaQuery(theme.breakpoints.up('sm'));
	const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
	const classes = useStyles(theme);
	const {title} = useContext(LayoutContext);
	const {toolbar} = useContext(LayoutContext);
	const {user} = useContext(UserContext);
	const {cars} = useContext(CarsContext);
	const {t} = useTranslation();
	const history = useHistory();
	const {setCarId} = useContext(SelectedCarContext)

	const toggleDrawer = () => {
		setDrawerOpen(!drawerOpen);
	};
	const handleSelectCar = (carId: number) => {
		if (!widthUpSm) toggleDrawer();
		setCarId(carId);
		history.push(`/cars/${carId}`)
	};
	const handleAccountSelected = () => {
		if (!widthUpSm) toggleDrawer();
		history.push('/account')
	};
	const handleCarsHomeSelected = () => {
		if (!widthUpSm) toggleDrawer();
		history.push("/cars")
	};


	const menu = user ? (
		<List>
			<ListItem button onClick={handleCarsHomeSelected}>
				<ListItemIcon style={{minWidth: 0}}><DriveEtaIcon /></ListItemIcon>
				<ListItem className={classes.expandableMenuItem}><ListItemText primary={t("titles.carsList")}/></ListItem>
			</ListItem>
			<List component="div" disablePadding>
				{
					cars.map(car => (
						<ListItem  className={classes.nested} key={car.id} button onClick={() => handleSelectCar(car.id)}>
							<ListItemText primary={car.name} />
						</ListItem>
						)
					)
				}
			</List>
			<Divider/>
			<ListItem button onClick={handleAccountSelected}>
				<ListItemIcon style={{minWidth: 0}}><UserAvatar user={user}/></ListItemIcon>
				<ListItem className={classes.expandableMenuItem}><ListItemText primary={t("titles.account")}/></ListItem>
			</ListItem>
		</List>
	) : (
		<List>
			<ListItem button onClick={() => history.push("/")}>
				<ListItemIcon><HomeIcon /></ListItemIcon>
				<ListItem className={classes.expandableMenuItem}><ListItemText primary={t("titles.home")}/></ListItem>
			</ListItem>
			<ListItem button onClick={() => history.push("/register")}>
				<ListItemIcon><AccountBoxIcon /></ListItemIcon>
				<ListItem className={classes.expandableMenuItem}><ListItemText primary={t("titles.register")}/></ListItem>
			</ListItem>
		</List>
	);

	return (
		<Box className={classes.root}>
			<ThemeProvider theme={theme}>
				<CssBaseline />
				<Helmet>
					<title>{title}</title>
				</Helmet>
				<AppBar
					position="fixed"
					className={classes.appBar}
				>
					<Box display="flex">
						<div className={classes.spacer}/>
						<Container maxWidth={"lg"}>
							<Toolbar>
								<IconButton
									color="inherit"
									aria-label="open drawer"
									onClick={toggleDrawer}
									edge="start"
									className={clsx(classes.menuButton, {
										[classes.hide]: drawerOpen,
									})}
								>
									<MenuIcon htmlColor="#FFF"/>
								</IconButton>
								<Typography className={classes.grow} variant="h6" noWrap>
									{title}
								</Typography>
								{toolbar}
							</Toolbar>
						</Container>
					</Box>
				</AppBar>
				<Box display="flex">
					<Drawer
						className={classes.drawer}
						variant={widthUpSm ? "permanent" : "temporary"}
						anchor="left"
						open={drawerOpen || widthUpSm}
						classes={{
							paper: classes.drawerPaper,
						}}
					>
						<div className={classes.drawerHeader}>
							{widthUpSm ? null : (
								<IconButton onClick={toggleDrawer}>
									<ChevronLeftIcon/>
								</IconButton>
							)}
						</div>
						<Divider/>
						{menu}
					</Drawer>
					<Container className={classes.content} maxWidth="sm">
							<div className={classes.toolbar}/>
							{children}
					</Container>
				</Box>
			</ThemeProvider>
		</Box>
	);
};

export default Layout;