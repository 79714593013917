import React, {ReactElement, useContext, useEffect, useState} from "react";
import {useHistory, useParams} from "react-router";
import {formatDateDmy} from "../util/formatting";
import {useTranslation} from 'react-i18next';
import {useAuthenticatedApi} from "../hooks/useAuthenticatedApi";
import SelectedCarContext from "./SelectedCarContext";
import UserContext from "./UserContext";
import {
	Box,
	makeStyles,
	Table,
	TableBody,
	TableCell,
	TableRow,
	Typography,
	Button, IconButton
} from "@material-ui/core";
import {AvatarGroup} from "@material-ui/lab";
import {StyledBadge} from "./Ride";
import UserAvatar from "./UserAvatar";
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import LayoutContext from "./Layout/LayoutContext";
import PaperWrapper from "./PaperWrapper";

const useStyles = makeStyles({
    confirmed: {
        '& .MuiBadge-badge': {
            backgroundColor: '#44b700',
            color: '#44b700',
        }
    },
    unconfirmed: {
        '& .MuiBadge-badge': {
            backgroundColor: '#FF0A00',
            color: '#FF0A00',
        }
    },
    avatarGroup: {
        justifyContent: "flex-end"
    }
});

export const ViewRide = (): React.ReactElement | null => {
    const {t} = useTranslation();
    const [rideDetails, setRideDetails] = useState<IRide | null>(null);
    const history = useHistory();
    const classes = useStyles();
    const {car, carId, users, reloadCar} = useContext(SelectedCarContext);
    const params = useParams<{carId: string | undefined, rideId: string | undefined}>();
    const rideId: number | null = (params.rideId) ? parseInt(params.rideId) : null;
    const [editable, setEditable] = useState<boolean>(false);
    const {user} = useContext(UserContext);
    const {setToolbar} = useContext(LayoutContext);
    const authenticatedApiClient = useAuthenticatedApi();

    const reloadRideDetails = (): void => {
        if (!rideId) return;
        authenticatedApiClient.get('/getRideDetails', {params: {rideId}}).then(res => {
            setRideDetails(res.data.ride);
            setEditable(res.data.editable);
        });
    };

    useEffect((): void => {
        reloadRideDetails();
    }, [rideId]);

	const handleDeleteRide = (): void => {
		if (window.confirm(t("questionConfirmDeleteRide"))) {
			authenticatedApiClient.post('/removeRide', {rideId}).then(res => {
				if (res.data.status === "SUCCESS") {
					reloadCar();
					history.push(`/cars/${carId}`);
				}
			});
		}
	};

	useEffect(() => {
		if (editable) {
			const toolbar = (
				<>
					<IconButton onClick={() => history.push(`/editRide/${carId}/${rideId}`)}>
						<EditIcon />
					</IconButton>
					<IconButton onClick={handleDeleteRide}>
						<DeleteIcon />
					</IconButton>
				</>
			);
			setToolbar(toolbar);
		}
		return () => {
			setToolbar(null);
		}
	}, [editable]);

    if (!rideDetails || !car || !users) return null;

    let confirmationSwitch: ReactElement | null = null;
    let currentRideUser: IRideParticipant | undefined = (user) ? rideDetails.drivers.find(rideUser => user.id === rideUser.userId) : undefined;
    const isConfirmable = (currentRideUser && !rideDetails.settled);

    const handleConfirmRide = (): void => {
        authenticatedApiClient.post("/setRideConfirmation", {rideId, confirmed: true}).then(() => {
            reloadCar();
            reloadRideDetails();
        });
    };

    const handleUnconfirmRide = (): void => {
        authenticatedApiClient.post("/setRideConfirmation", {rideId, confirmed: false}).then(() => {
			reloadCar();
            reloadRideDetails();
        });
    };

    if (isConfirmable) {
        if (currentRideUser?.confirmed){
            confirmationSwitch = (
             <Box>
                <Button color="primary" variant="contained" onClick={handleUnconfirmRide}>
                    {t("unconfirm")}
                </Button>
            </Box>)
        } else {
            confirmationSwitch = (
            <Box>
                <Button color="primary" variant="contained" onClick={handleConfirmRide}>
                    {t("confirm")}
                </Button>
            </Box>)
        }
    }

    const rideUsers: IUser[] = rideDetails.drivers.map((driver) => users.find(user => user.id === driver.userId) as IUser);
    const participantConfirmations: boolean[] = rideDetails.drivers.map(driver => driver.confirmed);
    const displayDate = formatDateDmy(new Date(rideDetails.date));

    return (
        <PaperWrapper>
			<Typography variant="h4">{rideDetails.name}</Typography>
			<Table>
				<TableBody>
					<TableRow>
						<TableCell>{t("date")}</TableCell>
						<TableCell align="right">{displayDate}</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>{t("distance")}</TableCell>
						<TableCell align="right">{rideDetails.end_mileage - rideDetails.start_mileage} km</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>{t("participants")}</TableCell>
						<TableCell align="right">
							<AvatarGroup max={4} className={classes.avatarGroup}>
							{
								rideUsers.map((user, index) => (
										<StyledBadge
											key={index}
											overlap="circle"
											anchorOrigin={{
												vertical: 'bottom',
												horizontal: 'right',
											}}
											variant="dot"
											className={participantConfirmations[index] ? classes.confirmed : classes.unconfirmed}
										>
											<UserAvatar key={user.id} user={user} />
										</StyledBadge>
									)
								)
							}
						</AvatarGroup></TableCell>
					</TableRow>
				</TableBody>
			</Table>
			<Box display="flex" justifyContent="flex-end" py={2}>
				<Box mr={2}>
					<Button color="secondary" variant="contained" onClick={() => history.goBack()}>
						{t("back")}
					</Button>
				</Box>
				{confirmationSwitch}
			</Box>
        </PaperWrapper>
    );
};