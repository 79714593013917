import {useHistory} from "react-router";
import {useContext} from "react";
import UserContext from "../components/UserContext";
import {AxiosError, AxiosRequestConfig, AxiosResponse} from "axios";
import ApiClient from "../util/apiclient";

export const useAuthenticatedApi = () => {
	const history = useHistory();
	const { setUser } = useContext(UserContext);

	const handleUnauthenticated = <R>(res: AxiosError): Promise<R> => {
		if (res.response && res.response.status === 401) {
			// Not logged in or session expired
			localStorage.removeItem("token");
			setUser(undefined);
			history.push("/");
		}
		return Promise.reject(res);
	}

	return {
		get: function <T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
			return ApiClient.get<T, R>(url, config).catch<R>(handleUnauthenticated);
		},
		post: function <T = any, R = AxiosResponse<T>>(url: string, data?: any, config?: AxiosRequestConfig): Promise<R> {
			return ApiClient.post<T, R>(url, data, config).catch<R>(handleUnauthenticated);
		}
	}
};
