import {Ride} from "./Ride";
import React from "react";
import {Refuel} from "./Refuel";
import {SettlementEvent} from "./SettlementEvent";
import {useTranslation} from 'react-i18next';
import {Box} from "@material-ui/core";

type EventListProps = {
    events?: IEvent[]
}
export const EventList = ({events}: EventListProps) => {
    const eventComponents: React.ReactElement[] = [];
    const {t} = useTranslation();

    if (!events) return null;

    for (let event of events){
        if (event.eventType === "ride"){
            const rideEvent = event as IRide;
            eventComponents.push(<Ride key={`ride-${rideEvent.id}`}{...rideEvent} />);
        }
        if (event.eventType === "refuel"){
            const refuelEvent = event as IRefuel;
            eventComponents.push(<Refuel key={`refuel-${refuelEvent.id}`}{...refuelEvent} />);
        }
        if (event.eventType === "settlement"){
            const settlementEvent = event as ISettlementEvent;
            eventComponents.push(<SettlementEvent key={`settlement-${settlementEvent.id}`}{...settlementEvent} />);
        }
    }

    return (
        <Box m={2}>
            {(eventComponents.length > 0) ? eventComponents : <p>{t("noEvents")}</p>}
        </Box>
    )
}