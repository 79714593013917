import React, {useState} from "react";
import {formatMileage} from "../util/formatting";
import {Box, makeStyles} from "@material-ui/core";
import clsx from "clsx";


const useStyles = makeStyles((theme) => ({
    root: {
        height: '46px',
        width: '190px',
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
		color: theme.palette.text.disabled
    },
    digit: {
        zIndex: 1,
        flexGrow: 1,
        lineHeight: '46px',
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: '25px'
    },
    inputOverlay: {
        position: 'absolute',
        width: '188px',
        height: '46px',
        background: 'transparent',
        border: 'none',
        zIndex: 2,
        textAlign: 'right',
        fontSize: '2em',
        padding: 0
    },
    edited: {
        color: theme.palette.primary.main
    },
    editable: {
        color: theme.palette.text.primary
    },
    digitBackground: {
      border: '1px solid #777',
      height: '100%',
      flexGrow: 1,
      boxSizing: 'border-box',
        backgroundColor: "#222"
    },
    digitBackgroundWrapper: {
      position: 'absolute',
      width: '100%',
      height: '46px',
      display: 'flex'
    },
    digitWhiteOverlay: {
      background: '#222',
      position: 'absolute',
      top: '8px',
      height: '32px',
      width: '188px',
      left: '1px'
    }
}));

export const MileageCounter = (props: {mileage: number; editable?: boolean; editHandler?: (value: number) => void}): React.ReactElement => {
    const classes = useStyles();
    const digitsBackground: React.ReactElement[] = [];
    const digits: React.ReactElement[] = [];
    const [editedInput, setEditedInput] = useState<string>("");

    let formattedMileage = formatMileage(props.mileage);
    formattedMileage = formattedMileage.substr(0, 6 - editedInput.length) + editedInput;

    for (let i = 0; i < 6; i++) {
        digitsBackground.push(<div className={classes.digitBackground} key={`digitbg-${i}`}></div>)
        digits.push(<div className={clsx(classes.digit, (editedInput.length - (i * -1) > 5) && classes.edited)}  key={`digit-${i}`}>{formattedMileage.charAt(i)}</div>);
    }

    const handleMileageChanged = (e: React.KeyboardEvent): void => {
        e.preventDefault();
        let newMileageStr: null | string = null;
        if (e.keyCode == 8) newMileageStr = editedInput.substr(0, editedInput.length - 1);
        if (e.keyCode > 47 && e.keyCode < 58 && editedInput.length < 6) newMileageStr = editedInput + String(e.key);

        if (newMileageStr !== null){
            const formatted = formatMileage(props.mileage).substr(0, 6 - newMileageStr.length) + newMileageStr;
            if (props.editHandler) props.editHandler(parseInt(formatted));
            setEditedInput(newMileageStr);
        }
    } ;

    const inputElement: React.ReactElement | null = (props.editable === true) ? <input className={classes.inputOverlay} onKeyDown={handleMileageChanged} onChange={() => {}} type="number" value="" /> : null;
    return (
        <Box display="flex" className={clsx(classes.root, props.editable && classes.editable)}>
            <div className={classes.digitBackgroundWrapper}>
                {digitsBackground}
                <Box className={classes.digitWhiteOverlay} />
            </div>
            {digits}
            {inputElement}
        </Box>
    )
};