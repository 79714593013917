import React, {ReactElement, useContext} from "react";
import {useHistory} from "react-router-dom";
import {formatCurrencyAmount, formatDateDmy} from "../util/formatting";
import {
    Avatar,
    Box,
    Card,
    CardContent,
    makeStyles,
    Typography,
} from "@material-ui/core";
import {AvatarGroup} from "@material-ui/lab";
import SelectedCarContext from "./SelectedCarContext";
import UserAvatar from "./UserAvatar";

const useStyles = makeStyles((theme) => ({
    avatar: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.text.primary
    },
    value: {
        minWidth: "50px",
        textAlign: "right"
    }
}));

export const Refuel = (props: IRefuel): ReactElement | null => {
    const classes = useStyles();
    const {users, carId} = useContext(SelectedCarContext);
    const history = useHistory();

    if (!users) return null;
    const refuelUser = users.find((user: IUser) => user.id === props.userId);

    if (refuelUser === undefined) return null;
    let displayDate = formatDateDmy(new Date(props.date));

    return (
        <Box my={2}>
            <Card onClick={() => history.push(`/viewRide/${carId}/${props.id}`)}>
                <CardContent>
                    <Box display="flex" alignItems="center">
                        <Avatar className={classes.avatar}>
                            <i className="fas fa-gas-pump"></i>
                        </Avatar>
                        <Box flexGrow={1} mx={2} display="flex" flexDirection="column">
                            <Typography variant="subtitle1">{formatCurrencyAmount(props.fuelAmount)} liter ({formatCurrencyAmount((Math.round((props.moneyAmount / props.fuelAmount) * 100) / 100))} p/l)</Typography>
                            <Typography variant="subtitle2">{displayDate}</Typography>
                        </Box>
                        <Box mx={2}>
                            <AvatarGroup max={4}>
                                <UserAvatar user={refuelUser} />)
                            </AvatarGroup>
                        </Box>
                        <Box className={classes.value}>
                            €{formatCurrencyAmount(props.moneyAmount)}
                        </Box>
                    </Box>
                </CardContent>
            </Card>
        </Box>
    );
};