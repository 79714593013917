import axios, {AxiosRequestConfig, AxiosResponse} from 'axios';

const ApiClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL
});

const originalPost = ApiClient.post;
const originalGet = ApiClient.get;

ApiClient.get = function <T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
    const customConfig: AxiosRequestConfig = {
        ...config,
        headers: {}
    };
    if (localStorage.getItem("token")) customConfig.headers = {'Authorization': "bearer " + localStorage.getItem("token")};
    return originalGet<T, R>(url, customConfig);
};

ApiClient.post = function <T = any, R = AxiosResponse<T>>(url: string, data?: any, config?: AxiosRequestConfig): Promise<R> {
    var formData = new FormData();

    const headers = (config?.headers) ? config.headers : {};

    const customConfig: AxiosRequestConfig = {
        ...config,
        headers
    };
    if (localStorage.getItem("token")) customConfig.headers = {'Authorization': "bearer " + localStorage.getItem("token")};

    if (data) {
        for (let key in data) {
            formData.append(key, data[key]);
        }
    }
    return originalPost<T, R>(url, formData, customConfig);
};

export default ApiClient;