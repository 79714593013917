import React, {ReactElement, useContext} from "react";
import {useHistory, useParams} from "react-router-dom";
import {formatDateDmy} from "../util/formatting";
import {
	Avatar, Badge,
	Box,
	Card,
	CardContent,
	createStyles,
	makeStyles,
	Theme,
	Typography,
	withStyles
} from "@material-ui/core";
import {AvatarGroup} from "@material-ui/lab";
import SelectedCarContext from "./SelectedCarContext";
import UserAvatar from "./UserAvatar";

const useStyles = makeStyles((theme) => ({
	avatar: {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.text.primary
	},
	confirmed: {
		'& .MuiBadge-badge': {
			backgroundColor: '#44b700',
			color: '#44b700',
		}
	},
	unconfirmed: {
		'& .MuiBadge-badge': {
			backgroundColor: '#FF0A00',
			color: '#FF0A00',
		}
	},
	value: {
		minWidth: "50px",
		textAlign: "right"
	}
}));


export const Ride = (props: IRide): ReactElement | null => {
	const classes = useStyles();
    const {users} = useContext(SelectedCarContext);
    const {carId} = useParams<{carId?: string}>();
	const history = useHistory();

	if (!users) return null;

    const rideUsers: IUser[] = users.filter(user => props.drivers.find(driver => driver.userId === user.id));
    const participantConfirmations: boolean[] = props.drivers.map(driver => driver.confirmed);

    let displayDate = formatDateDmy(new Date(props.date));

    return (
    	<Box my={2}>
			<Card onClick={() => history.push(`/viewRide/${carId}/${props.id}`)}>
				<CardContent>
					<Box display="flex" alignItems="center">
						<Avatar className={classes.avatar}>
							<i className="fas fa-road"></i>
						</Avatar>
						<Box flexGrow={1} mx={2} display="flex" flexDirection="column">
							<Typography variant="subtitle1">{props.name}</Typography>
							<Typography variant="subtitle2">{displayDate}</Typography>
						</Box>
						<Box mx={2}>
						<AvatarGroup max={4}>
							{
								rideUsers.map((user, index) => {
									return (
											<StyledBadge
												key={index}
												overlap="circle"
												anchorOrigin={{
													vertical: 'bottom',
													horizontal: 'right',
												}}
												variant="dot"
												className={participantConfirmations[index] ? classes.confirmed : classes.unconfirmed}
											>
												<UserAvatar key={user.id} user={user} />
											</StyledBadge>
										)
									}
								)
							}
						</AvatarGroup>
						</Box>
						<Box className={classes.value}>
							{props.end_mileage - props.start_mileage} km
						</Box>
					</Box>
				</CardContent>
			</Card>
		</Box>
    );
};

export const StyledBadge = withStyles((theme: Theme) =>
	createStyles({
		root: {
			border: 0,
		},
		badge: {
			boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
			'&::after': {
				position: 'absolute',
				top: 0,
				left: 0,
				width: '100%',
				height: '100%',
				borderRadius: '50%',
				border: '1px solid currentColor',
				content: '""',
			},
		}
	}),
)(Badge);