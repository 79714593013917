import {UserBadges} from "./UserBadges";
import React, {useContext} from "react";
import ApiClient from "../util/apiclient";
import {MileageCounter} from "./MileageCounter";
import { useTranslation } from 'react-i18next';
import CarsContext from "./CarsContext";

export const CarInvite = (props: CarInviteProps): React.ReactElement => {
    const {t} = useTranslation();
    const {reloadCars} = useContext(CarsContext);

    const handleAcceptInvite = (): void => {
        ApiClient.post('/handleInvite', {inviteId: props.inviteId, selection: "accept"}).then(() => {
            if (props.handleInviteAction) props.handleInviteAction("accept");
            reloadCars();
        });
    };

    const handleRejectInvite = (): void => {
        ApiClient.post('/handleInvite', {inviteId: props.inviteId, selection: "reject"}).then(() => {
            if (props.handleInviteAction) props.handleInviteAction("reject");
            reloadCars();
        });
    };

    return (
        <div className="wrapper">
            <strong>{props.inviterName + " " + t("invitedBy")}</strong>
            <div>
                <span>
                    <span>{props.name}</span>
                    <MileageCounter mileage={props.mileage} />
                </span>
                <div><UserBadges align="right" users={props.users}/></div>
            </div>
            <div>
                <div onClick={handleRejectInvite}><i className="fas fa-times"></i></div>
                <div onClick={handleAcceptInvite}><i className="fas fa-check"></i></div>
            </div>
        </div>
    );
};

interface CarInviteProps extends IInvite {
    handleInviteAction?: (selection: string) => void;
}