import {ReactNode, useContext} from "react";
import UserContext from "./UserContext";
import {useHistory} from "react-router";
import {LoadingIndicator} from "./LoadingIndicator";

const AuthenticatedPages = (props: {children: ReactNode}) => {
	const {user, loading} = useContext(UserContext);
	const history = useHistory();

	if (loading) {
		return <LoadingIndicator/>
	}
	if (!user) {
		history.push("/");
		return null;
	}
	return <>{props.children}</>;
};

export default AuthenticatedPages;