import React, {useContext} from "react";
import {useHistory} from "react-router";
import {MileageCounter} from "./MileageCounter";
import {
	Box, Button,
	Card,
	CardActionArea,
	CardActions,
	CardContent,
	CardMedia,
	makeStyles,
	Typography
} from "@material-ui/core";
import {AvatarGroup} from '@material-ui/lab';
import UserAvatar from "./UserAvatar";
import UserContext from "./UserContext";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles({
    root: {
        minWidth: 275,
    },
    title: {
        fontSize: 14,
    },
	media: {
		height: 200,
	},
});

export const Car = (props: ICar): React.ReactElement | null => {
	const classes = useStyles();
	const history = useHistory();
	const {t} = useTranslation();

	const {user} = useContext(UserContext);
	if (!user) return null;
	const carSelected = () => {
			history.push(`/cars/${props.id}`)
	};

	return (
		<Card className={classes.root}>
			<CardActionArea onClick={carSelected}>
				{
					props.image ? (
						<CardMedia
							className={classes.media}
							image={`${process.env.REACT_APP_API_URL}/${props.image}`}
						/>
					) : null
				}
				<CardContent>
					<Typography gutterBottom variant="h5" component="h2">
							{props.name}
					</Typography>
					<Box display="flex">
						<Box flexGrow={1}>
								<MileageCounter mileage={props.mileage} />
						</Box>
						<AvatarGroup max={4}>
							{
								props.users.map(user => <UserAvatar key={user.id} user={user} />)
							}
						</AvatarGroup>
					</Box>
				</CardContent>
			</CardActionArea>
			<CardActions>
				<Button size="small" color="primary" onClick={carSelected}>
						{t("view")}
				</Button>
				{user.id === props.owner_id ? (
					<Button size="small" color="primary" onClick={() => history.push(`/editCar/${props.id}`)}>
						{t("edit")}
					</Button>
				) : null}
			</CardActions>
		</Card>
	)
};