import {SettlementReport} from "./SettlementReport";
import * as React from "react";
import {useEffect, useState} from "react";
import {useParams} from "react-router";
import {useAuthenticatedApi} from "../hooks/useAuthenticatedApi";

export type GetUnsettledProps = {
    onFinished: () => void;
}
export const GetUnsettled = ({onFinished}: GetUnsettledProps): React.ReactElement | null => {
    const [settlement, setSettlement] = useState<ISettlement>();
    const {carId} = useParams<{carId?: string}>();
    const authenticatedApiClient = useAuthenticatedApi();

    useEffect((): void => {
        authenticatedApiClient.get('/getUnsettledDetails',{params: {carId}}).then(res => {
            setSettlement(res.data);
        })
    }, []);

    if (!settlement) return null;

    return (
        <SettlementReport onFinished={onFinished} isConfirmed={false} {...settlement}/>
    );
}