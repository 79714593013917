import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './components/App';
import "./util/i18n";
import {LoadingIndicator} from "./components/LoadingIndicator";

ReactDOM.render((
    <Suspense fallback={<LoadingIndicator/>}>
        <App />
    </Suspense>
), document.getElementById('root'));
