import {ReactElement} from "react";
import {UserBadge} from "./UserBadge";
import React from "react";

export const UserBadges = (props: {users: IUser[], statuses?: boolean[], align?: string}):ReactElement => {
    let userBadges: ReactElement[] = props.users.map((user, index) => {
        let status = (props.statuses) ? props.statuses[index] : undefined;
        return <UserBadge key={`user-${user.id}`} status={status} user={user}></UserBadge>
    });

    if (userBadges.length > 3){
        userBadges = userBadges.slice(0, 3);
        userBadges.push(<span key="more-icon"><i className={"fas fa-plus"}></i></span>);
    }

    return (
        <div>{userBadges}</div>
    );
}