import React, {createContext, Dispatch, ReactElement, SetStateAction, useContext, useEffect, useState} from "react";
import ApiClient from "../util/apiclient";
import CarsContext from "./CarsContext";

const defaultValue: SelectedCarContextValue = {
	reloadCar: () => {},
	setCarId: () => {},
	carId: undefined
};

type SelectedCarContextValue = {
	events?: IEvent[];
	users?: IUser[];
	reloadCar: () => void;
	setCarId: Dispatch<SetStateAction<number | undefined>>;
	carId?: number;
	car?: ICar;
}

const SelectedCarContext = createContext<SelectedCarContextValue>(defaultValue);
export default SelectedCarContext;


type SelectedCarContextProviderProps = {
	children?: ReactElement;
}

type CarDetailsResponse = {
	status: string;
	events: IEvent[];
	users: IUser[];
	car: ICar;
}

export const SelectedCarContextProvider = ({children}: SelectedCarContextProviderProps) => {
	const [events, setEvents] = useState<IEvent[] | undefined>();
	const [users, setUsers] = useState<IUser[] | undefined>();
	const [carId, setCarId] = useState<number | undefined>();
	const [carDetails, setCarDetails] = useState<ICar | undefined>();
	const {reloadCars} = useContext(CarsContext);

	const reloadCar = async () => {
		if (carId) {
			reloadCars();
			const res = await ApiClient.get<CarDetailsResponse>(`/getCarDetails`, {params: {carId}});
			if (res.data.status === "SUCCESS") {
				setEvents(res.data.events);
				setUsers(res.data.users);
				setCarDetails(res.data.car)
			}
		} else {
			setEvents(undefined);
			setUsers(undefined);
		}
	};

	const initialValue: SelectedCarContextValue = {
		events,
		users,
		reloadCar,
		setCarId,
		carId,
		car: carDetails
	};

	useEffect(() => {
		setEvents(undefined);
		setUsers(undefined);
		reloadCar()
	}, [carId]);

	return (
		<SelectedCarContext.Provider value={initialValue}>
			{children}
		</SelectedCarContext.Provider>
	)
};

