import React, {createContext, Dispatch, ReactElement, SetStateAction, useEffect, useState} from "react";
import ApiClient from "../util/apiclient";

const defaultValue: UserContextValue = {
	setUser: () => {},
	checkLoggedIn: () => {},
	setInvites: () => {},
	loading: false,
	invites: []
};

type UserContextValue = {
	user?: IUser;
	setUser: Dispatch<SetStateAction<IUser | undefined>>
	checkLoggedIn: () => void
	invites: IInvite[]
	setInvites: Dispatch<SetStateAction<IInvite[]>>,
	loading: boolean
}

const UserContext = createContext<UserContextValue>(defaultValue);
export default UserContext;


type UserContextProviderProps = {
	children?: ReactElement;
}

type LoggedInStatusResponse = {
	status: string;
	user?: IUser;
}

export const UserContextProvider = ({children}: UserContextProviderProps) => {
	const [user, setUser] = useState<IUser | undefined>();
	const [loading, setLoading] = useState<boolean>(false);
	const [invites, setInvites] = useState<IInvite[]>([]);
	const checkLoggedIn = async () => {
		const token: string | null = localStorage.getItem("token");

		if (token) {
			setLoading(true);
			const res = await ApiClient.get<LoggedInStatusResponse>("/loggedInStatus", {params: {token}});
			if (res.data.status === "LOGGED_IN") {
				setUser(res.data.user);
			} else {
				setUser(undefined);
			}
			setLoading(false)
		}
	};

	const initialValue: UserContextValue = {
		user,
		setUser,
		checkLoggedIn,
		invites,
		setInvites,
		loading
	};

	useEffect(() => {
		checkLoggedIn()
	}, []);

	return (
		<UserContext.Provider value={initialValue}>
			{children}
		</UserContext.Provider>
	)
};

