import {Box, Paper} from "@material-ui/core";
import * as React from "react";
import {ReactNode} from "react";

const PaperWrapper = ({children}: {children: ReactNode}) => {
	return (
		<Box m={2}>
			<Paper>
				<Box p={2}>
					{children}
				</Box>
			</Paper>
		</Box>
	)
};

export default PaperWrapper;