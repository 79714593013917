import React, {useEffect, useState} from "react";
import {useParams} from "react-router";
import {SettlementReport} from "./SettlementReport";
import {useAuthenticatedApi} from "../hooks/useAuthenticatedApi";

export const ViewSettlement = (): React.ReactElement | null => {
    const [settlementData, setSettlementData] = useState<ISettlement | null>(null);
    const {settlementId} = useParams<{settlementId?: string}>();
    const authenticatedApiClient = useAuthenticatedApi();

    useEffect(() => {
        authenticatedApiClient.get('/getSettlement', {params: {settlementId}}).then(res => {
            setSettlementData(res.data);
        });
    }, []);

    if (!settlementData) return null;

    return (
        <SettlementReport isConfirmed={true} {...settlementData} />
    );
};