import {useState} from "react";
import {useHistory, useParams} from "react-router";
import React from "react";
import {Link} from "react-router-dom";
import {ErrorMessages} from "./ErrorMessages";
import {useTranslation} from 'react-i18next';
import {useAuthenticatedApi} from "../hooks/useAuthenticatedApi";
import PaperWrapper from "./PaperWrapper";

export const UpdateUser = (): React.ReactElement => {
    const {t} = useTranslation();
    const [formVals, setFormVals] = useState<UpdateUserFormValues>({
        password: "",
        passwordConfirm: ""
    });
    const authenticatedApiClient = useAuthenticatedApi();

    const [errorMessages, setErrorMessages] = useState<string[]>([]);

    const handleChangeFormVals = (e: React.FormEvent<HTMLInputElement>): void => {
        setFormVals({...formVals, [e.currentTarget.id]: e.currentTarget.value})
    };

    const [updated, setUpdated] = useState<boolean>(false);

    const {resetToken} = useParams<{resetToken?: string}>();

    const submit = (e: React.FormEvent<HTMLInputElement>): void => {
        e.preventDefault();
        setErrorMessages([]);
        if (formVals.password !== formVals.passwordConfirm) {
            setErrorMessages([t("errors.PASSWORDS_DONT_MATCH")]);
            return;
        }

        const data = {
            password: formVals.password,
            resetToken: resetToken as String,
        };

        authenticatedApiClient.post("/updateUser", data).then(res => {
            if (res.data.status === "SUCCESS") {
                setUpdated(true);
            } else {
                setErrorMessages(res.data.errors);
            }
        });
    };

    if (updated){
        return(
            <PaperWrapper>
                <p>{t("passwordUpdated")}</p>
                <Link to="/login"><input type="button" className="button" value={t("login") as string} /></Link>
            </PaperWrapper>
        );
    } else {
        return (
            <PaperWrapper>
                <ErrorMessages errors={errorMessages}/>
                <form>
                    <div>
                        <label htmlFor="password">{t("password")}</label>
                        <input id="password" type="password" value={formVals.password} onChange={handleChangeFormVals} />
                    </div>
                    <div>
                        <label htmlFor="password">{t("confirmPassword")}</label>
                        <input id="passwordConfirm" type="password" value={formVals.passwordConfirm} onChange={handleChangeFormVals} />
                    </div>
                    <input className="button" value={t("update") as string} type="button" onClick={submit}/>
                </form>
            </PaperWrapper>
        );
    }
};

interface UpdateUserFormValues {
    password: string;
    passwordConfirm: string;
}