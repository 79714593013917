import React from 'react';
import {Home} from "./Home";
import {Switch, Route, Router} from "react-router-dom";
import Layout from "./Layout/Layout";
import {createBrowserHistory} from "history";
import LoginForm from "./LoginForm";
import {RequestPasswordReset} from "./RequestPasswordReset";
import {CarDetails} from "./CarDetails";
import {CarsList} from "./CarsList";
import {EditRide} from "./EditRide";
import {Register} from "./Register";
import {SendInvite} from "./SendInvite";
import {ViewRide} from "./ViewRide";
import {UserContextProvider} from "./UserContext";
import {CarsContextProvider} from "./CarsContext";
import {SelectedCarContextProvider} from "./SelectedCarContext";
import AuthenticatedPages from "./AuthenticatedPages";
import {LayoutContextProvider} from "./Layout/LayoutContext";
import {ViewRefuel} from "./ViewRefuel";
import {EditRefuel} from "./EditRefuel";
import {Unconfirmed} from "./Unconfirmed";
import {ViewSettlement} from "./ViewSettlement";
import {Stats} from "./Stats";
import PaperWrapper from "./PaperWrapper";
import WrappedRoute from "./WrappedRoute";
import {UpdateUser} from "./UpdateUser";
import Account from "./Account";
import EditCar from "./EditCar";

const App: React.FC = () => {
  const history = createBrowserHistory();

  return (
	  <Router history={history}>
		  <UserContextProvider>
			  <CarsContextProvider>
				  <SelectedCarContextProvider>
					  <LayoutContextProvider>
							<Layout>
								<Switch>
									<Route exact path="/">
										<Home />
									</Route>
									<Route exact path="/login">
										<PaperWrapper
											><LoginForm/>
										</PaperWrapper>
									</Route>
										<Route exact path="/requestPasswordReset">
											<RequestPasswordReset />
										</Route>
										<Route exact path="/register">
											<Register />
										</Route>
										<Route exact path="/updateUser/:resetToken">
											<UpdateUser />
										</Route>
										<AuthenticatedPages>
											<Route exact path="/cars">
												<CarsList />
											</Route>
											<WrappedRoute exact path="/editCar/:carId">
												<EditCar />
											</WrappedRoute>
											<WrappedRoute exact path="/cars/:carId">
												<CarDetails />
											</WrappedRoute>
											<WrappedRoute exact path="/sendInvite/:carId">
												<SendInvite />
											</WrappedRoute>
											<WrappedRoute exact path="/stats/:carId">
												<Stats/>
											</WrappedRoute>
											<Route exact path="/viewSettlement/:settlementId">
												<ViewSettlement />
											</Route>
											<WrappedRoute exact path="/unconfirmed/:carId">
												<Unconfirmed />
											</WrappedRoute>
											<WrappedRoute exact path="/viewRefuel/:carId/:refuelId">
												<ViewRefuel />
											</WrappedRoute>
											<WrappedRoute exact path="/editRefuel/:carId/:refuelId">
												<EditRefuel />
											</WrappedRoute>
											<WrappedRoute exact path="/viewRide/:carId/:rideId">
												<ViewRide/>
											</WrappedRoute>
											<WrappedRoute exact path="/editRide/:carId/:rideId">
												<EditRide/>
											</WrappedRoute>
											<WrappedRoute exact path="/account">
												<Account />
											</WrappedRoute>
										</AuthenticatedPages>
								{/*<Route exact path="/invite/:inviteToken">*/}
									{/*<Invite />*/}
								{/*</Route>*/}
								</Switch>
							</Layout>
		  			</LayoutContextProvider>
		 			</SelectedCarContextProvider>
        </CarsContextProvider>
      </UserContextProvider>
  	</Router>
  );
};

export default App;
