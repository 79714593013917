import React, {useContext, useEffect, useState} from "react";
import {EventList} from "./EventList";
import {AxiosResponse} from "axios";
import {useAuthenticatedApi} from "../hooks/useAuthenticatedApi";
import {Box, makeStyles} from "@material-ui/core";
import SelectedCarContext from "./SelectedCarContext";

const useStyles = makeStyles({
    carImage: {
        backgroundSize: "cover",
        backgroundPosition: "center center",
        height: '220px'
    }
});

export const Unconfirmed = (): React.ReactElement | null => {
    const classes = useStyles();
    const [unconfirmedEvents, setUnconfirmedEvents] = useState<IEvent[]>([]);
    const authenticatedApiClient = useAuthenticatedApi();
    const {car} = useContext(SelectedCarContext);

    useEffect(() => {
        if (!car) return;

        authenticatedApiClient.get('/getUnconfirmedEvents', {params: {carId: car.id}}).then((res: AxiosResponse) => {
            setUnconfirmedEvents(res.data.events);
        });
    }, [car]);

    if (!car) return null;

    return (
        <div>
            {car.image ? (
                <Box className={classes.carImage} style={{backgroundImage: `url("${process.env.REACT_APP_API_URL}/${car.image}")`}} />
            ) : null}
            <EventList events={unconfirmedEvents}/>
        </div>
    )
}