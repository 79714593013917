import {ReactElement, useContext} from "react";
import * as React from "react";
import {useHistory} from "react-router";
import {LoadingIndicator} from "./LoadingIndicator";
import {Link} from "react-router-dom";
import LoginForm from "./LoginForm";
import {useTranslation, Trans} from 'react-i18next';
import UserContext from "./UserContext";
import PaperWrapper from "./PaperWrapper";

export const Home = (): ReactElement => {
    const {t} = useTranslation();
    const {user, loading} = useContext(UserContext);
    const history = useHistory();

    if (user) history.push("/cars");

    if (loading)return <LoadingIndicator />;
    else return (
        <PaperWrapper>
            <p>{t("home.introText")}</p>
            <LoginForm />
            <p><Trans i18nKey="home.signUp">To sign up, click <strong>register</strong> to set up your account.</Trans></p>
            {(process.env.REACT_APP_REGISTRATION_ENABLED === "true") ? <Link to="/register"><input type="button" className="button" value={t("register") as string}/></Link> : null}
        </PaperWrapper>
    )
};