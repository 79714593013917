import React, {ReactElement, useContext, useState} from "react";
import {Car} from "./Car";
import {CarInvite} from "./CarInvite";
import { useTranslation } from 'react-i18next';
import {Box, Button} from "@material-ui/core";
import AddCar from "./AddCar";
import CarsContext from "./CarsContext";
import UserContext from "./UserContext";

export const CarsList = (): React.ReactElement => {
    const {t} = useTranslation();
    const {cars, reloadCars} = useContext(CarsContext);
    const {invites} = useContext(UserContext);

    const [showOverlay, setShowOverlay] = useState<"ADD_CAR" | undefined>();
    const handleOverlayFinished = () => {
        setShowOverlay(undefined);
    };


    if (showOverlay === "ADD_CAR"){
        return <AddCar onFinished={handleOverlayFinished}/>;
    }

    let inviteComponents: ReactElement[] = invites.map((invite: IInvite) => (
            <Box key={`invite-${invite.id}`} py={2}>
                <CarInvite handleInviteAction={reloadCars} {...invite} />
            </Box>
        )
    );

    const carElements: React.ReactElement[] = cars.map(car => (
        <Box key={`car-${car.id}`} my={2}>
            <Car {...car}/>
        </Box>
        )
    );
    if (cars.length === 0 && invites.length === 0) carElements.push(<p key="no-cars-info">{t("noCars")}</p>)

    return (
        <Box m={2}>
            {inviteComponents}
            {carElements}
            <Button onClick={() => setShowOverlay("ADD_CAR")} variant="contained" color="primary">
                {t("addCar")}
            </Button>
        </Box>
    );
};