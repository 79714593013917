import PaperWrapper from "./PaperWrapper";
import {ReactElement, useContext, useEffect, useRef, useState} from "react";
import UserContext from "./UserContext";
import {useTranslation} from "react-i18next";
import DeleteIcon from '@material-ui/icons/Delete';
import * as React from "react";
import {Box, Button, Fab, Grid, makeStyles, Paper, TextField, useTheme} from "@material-ui/core";
import {useAuthenticatedApi} from "../hooks/useAuthenticatedApi";
import SelectedCarContext from "./SelectedCarContext";
import EditIcon from '@material-ui/icons/Edit';

const useStyles = makeStyles((theme) => ({
	root: {
		minWidth: 275,
	},
	title: {
		fontSize: 14,
	},
	media: {
		height: 200,
		backgroundSize: "cover",
		backgroundPosition: "center center",
		position: "relative"
	},
	fabWrapper: {
		position: "absolute",
		bottom: theme.spacing(2),
		right: theme.spacing(2)
	}
}));

const EditCar = (): ReactElement | null => {
	const classes = useStyles();
	const {t} = useTranslation();
	const fileRef = useRef<HTMLInputElement>(null);
	const authenticatedApi = useAuthenticatedApi();
	const {car, users, reloadCar} = useContext(SelectedCarContext);
	const [carName, setCarName] = useState<string>("");
	const apiClient = useAuthenticatedApi();
	const theme = useTheme();

	useEffect(() => {
		if (!car) return;
		setCarName(car.name);
	}, [car]);

	if (!users || !car) return null;

	const handleFileChanged = () => {
		if (fileRef.current && fileRef.current.files && fileRef.current.files.length) {
			authenticatedApi.post("/uploadCarImage", {
				image: fileRef.current.files[0],
				carId: car.id
			}, {
				headers: {
					'content-type': 'multipart/form-data'
				}
			}).then(res => {
					reloadCar();
				}
			)
		}
	};

	const handleDeleteImage = () => {
		apiClient.post("/removeCarImage", {carId: car.id}).then(reloadCar);
	};

	const handleUpdateCarName = ((e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		setCarName(e.currentTarget.value);
	});

	const handleSubmit = (e: React.FormEvent<HTMLButtonElement>) => {
		e.preventDefault();
		apiClient.post("/updateCar", {carId: car.id, name: carName}).then(reloadCar);
	};

	const uploadInput = (
		<input
			ref={fileRef}
			accept="image/*"
			hidden
			type="file"
			onChange={handleFileChanged}
		/>
	);

	return (
		<Paper style={{overflow: "hidden"}}>
			<form>
				{car.image ? (
					<>
						<Box className={classes.media} style={{backgroundImage: `url("${process.env.REACT_APP_API_URL}/${car.image}")`}}>
							<Box className={classes.fabWrapper}>
								<Fab color="secondary" component="label" onClick={handleDeleteImage}>
									<DeleteIcon />
								</Fab>
								<Fab color="primary" style={{marginLeft: theme.spacing(2)}} component="label">
									{uploadInput}
									<EditIcon />
								</Fab>
							</Box>
						</Box>
					</>
				) : (
					<Box p={2}>
						<Button variant="contained" component="label">
							{t("uploadImage")}
							{uploadInput}
						</Button>
					</Box>
				)}
				<Box p={2}>
					<Box my={2}>
						<TextField
							label={t("name")}
							id="name"
							variant="filled"
							size="small"
							value={carName}
							onChange={handleUpdateCarName}
						/>
					</Box>
					<Box my={2}>
						<Button color="primary" type="submit" variant="contained" onClick={handleSubmit}>
							{t("save")}
						</Button>
					</Box>
				</Box>
			</form>
		</Paper>
	)
};

export default EditCar;