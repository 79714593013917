import * as React from "react";
import {formatCurrencyAmount, formatDateTime} from "../util/formatting";
import {useHistory} from "react-router";
import {ErrorMessages} from "./ErrorMessages";
import {useContext, useState} from "react";
import {useTranslation} from 'react-i18next';
import {useAuthenticatedApi} from "../hooks/useAuthenticatedApi";
import {
    Box, Button,
    createStyles,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Theme
} from "@material-ui/core";
import SelectedCarContext from "./SelectedCarContext";
import PaperWrapper from "./PaperWrapper";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dueNegative: {
            color: '#ff3232'
        },
        duePositive: {
            color: '#30a03a'
        }
    })
);

export const SettlementReport = ({onFinished, ...props}: ISettlementProps): React.ReactElement => {
    const {carId, reloadCar} = useContext(SelectedCarContext);
    const history = useHistory();
    const {t} = useTranslation();
    const [errorMessages, setErrorMessages] = useState<string[]>([]);
    const authenticatedApiClient = useAuthenticatedApi();
    const classes = useStyles();

    const handleConfirmSettlement = (): void => {
        if (!carId) return;
        authenticatedApiClient.post('/confirmSettlement', {carId}).then(res => {
            if (res.data.status === "SUCCESS") {
                reloadCar();
                if (onFinished) onFinished();
                else history.goBack();
            } else {
                setErrorMessages(res.data.errors);
            }
        });
    };

    const userRows: React.ReactElement[] = props.users.map((user: ISettlementUser) => {
        const owesAmount = user.amountDue < 0;

        const displayAmount = (owesAmount) ? user.amountDue * -1 : user.amountDue;

        return (
            <TableRow key={`user-${user.id}`}>
                <TableCell>{user.name}</TableCell>
                <TableCell>{formatCurrencyAmount(user.distance)} km</TableCell>
                <TableCell>€{formatCurrencyAmount(user.paid)}</TableCell>
                <TableCell className={owesAmount ? classes.dueNegative : classes.duePositive}>€{formatCurrencyAmount(displayAmount)}</TableCell>
            </TableRow>
        )
    });

    const handleViewUnconfirmedEvents = (): void => {
        history.push(`/unconfirmed/${carId}`)
    };

    let unconfirmedEventsWarning = null;
    if (!props.isConfirmed && props.haveUnconfirmedEvents){
        unconfirmedEventsWarning = (
            <>
                <p>{t("unconfirmedEventsWarning")}</p>
                <Box py={2}>
                    <Button color="primary" variant="contained" onClick={handleViewUnconfirmedEvents}>
                        {t("view")}
                    </Button>
                </Box>
            </>
        )
    }

    const canConfirm = (!props.isConfirmed && !props.haveUnconfirmedEvents && (props.totalDistance > 0 || props.totalPaid > 0));

    const dateRow = (props.timestamp) ? (
        <TableRow>
            <TableCell>{t("date")}</TableCell>
            <TableCell align="right">{formatDateTime(props.timestamp as number)}</TableCell>
        </TableRow>
    ) : (
        <TableRow>
            <TableCell>{t("lastSettlement")}</TableCell>
            <TableCell align="right">{(props.lastSettlementTimestamp) ? formatDateTime(props.lastSettlementTimestamp) : t("never")}</TableCell>
        </TableRow>
    );

    const handleBack = () => {
        if (onFinished) onFinished();
        else history.goBack();
    }

    return (
        <PaperWrapper>
            <Box py={2}>
                {unconfirmedEventsWarning}
                <ErrorMessages errors={errorMessages}/>
                <Table>
                    <TableBody>
                        {dateRow}
                        <TableRow>
                            <TableCell>{t("totalDistance")}</TableCell>
                            <TableCell align="right">{props.totalDistance}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>{t("totalMoneySpent")}</TableCell>
                            <TableCell align="right">€{formatCurrencyAmount(props.totalPaid)}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>{t("numberOfRides")}</TableCell>
                            <TableCell align="right">{props.numberOfRides}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>{t("totalFuelConsumed")}</TableCell>
                            <TableCell align="right">{formatCurrencyAmount(props.totalFuelConsumed)} liter</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>{t("fuelConsumption")}</TableCell>
                            <TableCell align="right">{formatCurrencyAmount(props.fuelConsumption)} km/l</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Box>
            <Box py={2}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>{t("user")}</TableCell>
                            <TableCell><i className="fas fa-road"></i></TableCell>
                            <TableCell><i className="fas fa-dollar-sign"></i></TableCell>
                            <TableCell><i className="fas fa-random"></i></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {userRows}
                    </TableBody>
                </Table>
            </Box>
            <Box display="flex" justifyContent="flex-end" py={2}>
                <Box mr={2}>
                    <Button color="secondary" variant="contained" onClick={handleBack}>
                        {t("back")}
                    </Button>
                </Box>
        {(canConfirm) ? (
                <Button color="primary" variant="contained" onClick={handleConfirmSettlement}>
                    {t("confirmNow")}
                </Button>
            ) : null}
            </Box>
        </PaperWrapper>
    )
};

interface ISettlementProps extends ISettlement{
    isConfirmed: boolean;
    onFinished?: () => void;
}