import React, {ReactElement} from "react";
import { formatDateTime, padToTwo} from "../util/formatting";
import { useHistory} from "react-router-dom";
import {Avatar, Box, Card, CardContent, makeStyles, Typography} from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
    avatar: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.text.primary
    }
}));


export const SettlementEvent = (props: ISettlementEvent): ReactElement | null => {
    let settlementDateTime = formatDateTime(props.timestamp);
    const classes = useStyles();
    const history = useHistory();

    let date = new Date(props.date);
    let displayDate = padToTwo(date.getDate()) + "-" + padToTwo(date.getMonth() + 1);

    return (
        <Box my={2}>
            <Card onClick={() => history.push(`/viewSettlement/${props.id}`)}>
                <CardContent>
                    <Box display="flex" alignItems="center">
                        <Avatar className={classes.avatar}>
                            <i className="fas fa-random"></i>
                        </Avatar>
                        <Box flexGrow={1} mx={2} display="flex" flexDirection="column">
                            <Typography variant="subtitle1">{settlementDateTime}</Typography>
                        </Box>
                        <Box>
                            {displayDate}
                        </Box>
                    </Box>
                </CardContent>
            </Card>
        </Box>
    );
};