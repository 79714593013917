import React, {createContext, Dispatch, ReactElement, ReactNode, SetStateAction, useState} from "react";

type LayoutContextValue = {
	title: string;
	setTitle: Dispatch<SetStateAction<string>>;
	toolbar: ReactNode;
	setToolbar: Dispatch<SetStateAction<ReactNode>>
}

const defaultValue: LayoutContextValue = {
	title: "",
	setTitle: () => {},
	toolbar: null,
	setToolbar: () => {}
}


const LayoutContext = createContext<LayoutContextValue>(defaultValue);
export default LayoutContext;


type LayoutContextProviderProps = {
	children?: ReactNode;
}

export const LayoutContextProvider = ({children}: LayoutContextProviderProps) => {
	const [title, setTitle] = useState<string>("");
	const [toolbar, setToolbar] = useState<ReactNode>(null);
	const initialValue: LayoutContextValue = {
		title,
		setTitle,
		toolbar,
		setToolbar
	};

	return (
		<LayoutContext.Provider value={initialValue}>
			{children}
		</LayoutContext.Provider>
	)
};

